import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/index.vue'
import worktableView from '../views/work/worktable.vue'
import { START_ALIGNMENT } from 'element-plus/es/components/virtual-list/src/defaults'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '首页',
    component: HomeView,
    meta: { keepAlive: true,title:'奈特喜商城'  }
  },
  {
    path: '/work',
    name: '工作台',
    component:worktableView,
    meta: {title:'工作台'  }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),
    meta: {title:'奈特喜商城'  }
  },
  {
    path: '/productDetails',
    name: '商品详情',
    component: () => import( '../views/product/productDetails.vue'),
    meta: { keepAlive: true,title:'商品详情'   }
  } ,
  {
    path: '/shoppingAdd',
    name: '添加寻购',
    component: () => import(/* webpackChunkName: "shoppingAdd" */ '../views/userInfo/shoppingAdd.vue'),
    meta: {title:'添加寻购'  }
  },
  {
    path: '/cart',
    name: '购物车',
    component: () => import(/* webpackChunkName: "mycard" */ '../views/cart/index.vue'),
    meta: {title:'购物车'  }
  }, {
    path: '/cartDetails',
    name: '购物车详情',
    component: () => import(/* webpackChunkName: "cartDetails" */ '../views/cart/cartDetails.vue'),
    meta: {title:'购物车详情'  }
  },{
    path: '/order',
    name: '订单管理',
    component: () => import(/* webpackChunkName: "order" */ '../views/order/index.vue'),
    meta: { keepAlive: true,title:'订单管理'  }
  },{
    path: '/invoiceRecords',
    name: '开票记录',
    component: () => import(/* webpackChunkName: "invoiceRecords" */ '../views/order/invoiceRecords.vue'),
  },{
    path: '/orderDetails',
    name: '订单详情',
    component: () => import(/* webpackChunkName: "orderDetails" */ '../views/order/orderDetails.vue'),
    meta: {title:'订单详情'  }
  },{
    path: '/requestRefund',
    name: '申请售后',
    component: () => import(/* webpackChunkName: "requestRefund" */ '../views/order/requestRefund.vue'),
    meta: {title:'申请售后'  }
  },{
    path: '/userInfo',
    name: '账户信息',
    component: () => import(/* webpackChunkName: "userInfo" */ '../views/userInfo/index.vue'),
    meta: { keepAlive: true, title:'账户信息' }
  },
  {
    path: '/procureWrap',
    name: '采购统计',
    component: () => import(/* webpackChunkName: "procureWrap" */ '../views/procureWrap/index.vue'),
    meta: {title:'采购统计'  }
  },{ 
    path: '/RefundAfterSales',
    name: '退款售后',
    component: () => import(/* webpackChunkName: "RefundAfterSales" */ '../views/RefundAfterSales/index.vue'),
    meta: { keepAlive: true,title:'退款售后'   }
  },
  {
    path: '/RefundAfterSales/refunds',
    name: '退款售后详情',
    component: () => import(/* webpackChunkName: "refunds" */ '../views/RefundAfterSales/refunds.vue'),
    meta: {title:'退款售后详情'  }
  },
  {
    path: '/shoppingDetails',
    name: '寻购详情',
    component: () => import(/* webpackChunkName: "userInfo" */ '../views/userInfo/shoppingDetails.vue'),
    meta: {title:'寻购详情'  }
  },
  {
    path: '/storedetails',
    name: '店铺详情',
    component: () => import(/* webpackChunkName: "userInfo" */ '../views/storeBox/storedetails.vue'),
    meta: {title:'店铺详情'  }
  },{
    
    path: '/merchants',
    name: '商家入驻',
    component: () => import(/* webpackChunkName: "userInfo" */ '../views/merchants/index.vue'),
    meta: {title:'商家入驻'  }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
 
})
router.beforeEach((to, from, next) => {
  console.log(to.meta.title,'to.meta.title' ,window.document.title)
  window.document.title = String(to.meta.title)
  next();
})

 
export default router
