<template>
    <div @click="backTop" >
        <div class="sidebarItem cur">
          <div class="topImg"></div>
        </div>
    </div>
</template>

<script setup>
import { ref ,toRefs,onMounted,onUnmounted,defineProps} from "vue";
const props =defineProps({
    elmId:{ //元素id
        default:'goodsRef'
    },
})
 const backTop = () => {
    let top=document.getElementById(props.elmId)
    // console.log(top,"top")
    // top.scrollIntoView({ behavior: "smooth"})
    // top.scrollIntoView(true)
        top.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})

  }
</script>

<style lang="scss" scoped>
.sidebarItem{
    width:72px;
    height:72px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.topImg{
  background-image: url('@/assets/img/icon_top.png');
      width:28px;height:28px;margin-bottom:3px;
}
.sidebarItem:hover{
    background:#FFF5F5;
    .topImg{
    background-image: url('@/assets/img/icon_top1.png');
    }
  }
.side .active {
  animation: active 1s;
  -webkit-animation: active 1s;
  overflow: hidden;
}
@keyframes active {
  0% {
    height: 0px;
  }
 
  100% {
    height: 60px;
  }
}
.side .inactive {
  animation: inactive 1s;
  -webkit-animation: inactive 1s;
  animation-fill-mode: forwards;
  overflow: hidden;
}
 
@keyframes inactive {
  0% {
    height: 60px;
  }
 
  100% {
    height: 0px;
  }
}
</style>