<template>
    <div class="homeWrap" ref="homeWrap" v-infinite-scroll="loadlistPage" @scroll="handleScroll"  style="max-height:87vh;overflow-anchor: none; overflow: auto"  :infinite-scroll-distance="260" :infinite-scroll-immediate="false"  :infinite-scroll-disabled="false">
    <!-- 导航 -->
   <div class="homeTop flex" id="homeRef">
    <div class="flex mar80 cur" @mouseover ="classShow=true"><img src="@/assets/img/Frameclass.png" alt="" class="mar1" style="width: 20px;height: 20px;"> <span class="fontS16 co6">全部分类</span></div>
    <div class="flex">
      <div v-for="(item,index) in nav" :key="index" class="navItem cur" @click="changeTab(index)">
        <div class="fontS16 co6 " :class="tabIndex==index?'navActiveItem':''">{{ item.name }}</div>
      </div>
    </div>
   </div>

   <!-- 全部分类 @mouseleave="classShow=false"-->
   <!--  -->
   <!--  @mouseleave="closeShow" v-throttle="closeShow" -->
   <div class="classWrapBox df" v-if="classShow"  @mouseleave="classShow=false">
    <div class="classLeft">
      <!-- :class="index==0?'activeItem':''" -->
      <template v-for="(item,index) in classList" :key="index">
      <div class="classItem fontS14 co3 cur elipes1"  :class="activecategry == index?'activeItem':''"   @mouseenter="activecategry=index">{{item.name}}</div>
    </template>
    </div>
    <div class="classRight mL26 mt18" v-if="activecategry || activecategry === 0">
      <div class="rightItem df" v-for="(val,index) in classList[activecategry].children" :key="index" >
        <div class="title flex cur fontS14 co3" style="width: 76px;"><div>{{val.name}}</div> <div class="more"></div></div>
        <div class="productWrap df flexW flex1 ">
          <div class="productItem mar20  marB2 " v-for="(v,j) in val.children" :key="j" @click="categryClick(activecategry,index,j,v)  ">
            <div class="productImg"><img v-if="v.icon" :src="v.icon" alt="" style="width: 100%; height: 100%;object-fit: contain;"></div>
            <div class="fontS14 co3 txt">{{v.name}}</div>
          </div>
        </div>
      </div>
    </div>
   </div>
   <!-- banner -->
   <div class="banner mt24" v-show="tabIndex==0">
    <img src="@/assets/img/banner.png" alt="">
  </div>
  <!-- 资料弹窗 -->
  <el-dialog v-model="noticeShow" title="" width="427"  :show-close="false">
        <div class="fontS20 co3 flex"><img src="@/assets/img/Group33817.png" alt="" style="width: 20px;height: 20px;margin-right: 4px;"> 注册成功</div>
        <div class="co6 fontS16 marT2">还没有认证信息哦，认证后享受更多权益。<span class="themC cur" @click="router.push({path:'/userInfo'})">去完善</span></div>
        <div class="dialog-footer flexC" style="margin-top: 36px;justify-content: flex-end;">
          <div class="noticeBtn cof fontS18 cur" @click="noticeShow = false;">进入商城</div>
        </div>
    </el-dialog>
    <!-- 微信 -->
    <!-- <WechatService v-model="wechatShow" :title='contactData.title' :name="contactData.desc" :imgShow="contactData.image"></WechatService> -->
    <el-dialog v-model="wechatShow" title="" width="567" :show-close="true">
      <div class="fontS20 text-c co3"> {{ contactData?.title }} </div>
      <div class="imgWeChat">
        <img :src="contactData?.image" alt="" style="width: 100%" />
      </div>
      <div class="co6 fontS16 text-c" style="margin-bottom: 24px;">{{ contactData?.desc }}</div>
    </el-dialog>
   <!-- q -->
    <AllProduct v-if="tabIndex!=0" class="mt24" :isKeyshow="showKey" @getGoodsList="getGoodsList" :totalGoods="total" :isShowTypea="tabIndex==2?1:(tabIndex==3?2:false)" ref="allProductCom"></AllProduct>
   <div class="listBox ">
      <div class="listItem mt24 cur" v-for="(item,index) in goodsData" :key="index" @click="getDetails(item)">
        <div class="itemTop"><img :src="item.cover " alt="" style="width: 100%;height: 100%;object-fit:contain"></div>
        <div class="itemBottom">
          <div class="fontS14 co3 elipes2" style="height: 38px;">{{ item.name }}</div>
            <div>
              <div class="themC mt10"><span class="fontS24">￥</span><span class="fontS20">{{ item.min_platform_price_cny }}</span></div>
              <div class="flexJBC mt10"><div class="fontS12 co9">建议零售价:<span>￥{{ item.min_retail_price_cny  }}</span></div><div class="fontS12 themC">毛利:{{item.max_gross_profit_cny}}%</div></div>
              <div class="flexJBC mt10"> <div class="fontS12 themC">{{item.is_direct==1?'日本直邮':''}}{{item.is_direct==1&&item.is_bonded==1?'/':''}}{{item.is_bonded==1?'保税区发货':''}}</div> <div class="fontS12 co9">销量 {{item.turnovers>100000?'10w+':item.turnovers>10000?'1w+':item.turnovers>999?'999+':item.turnovers}}</div></div>
            </div>
            <!-- <div v-else>
             <div class="themC mt10"><span class="fontS18">￥价格请咨询客服</span></div>
               <div class="flexJBC mt10"><div class="fontS12 co9">建议零售价:-</div><div class="fontS12 themC">毛利:-</div></div>
              <div class="flexJBC mt10"> <div class="fontS12 themC">日本直邮/保税区发货</div> <div class="fontS12 co9">销量-</div></div>
             </div> -->
        </div>
      </div>
      <div class="pageWrap4 fontS16" v-if="goodsData.length>0">
                <el-config-provider :locale="zhCn">
                <el-pagination
                  v-model:current-page="listData.page"
                  v-model:page-size="listData.limit"
                  background
                  layout="prev, pager, next, jumper"
                  :total="total"
                  @current-change="handleCurrentChange"
                />
              </el-config-provider>
    </div>
     
   </div>
  
   <el-empty description="暂无数据" v-if="goodsData.length == 0"  style="background: #ffff;border-radius: 12px; width: 1168px;" class="mt24"/>
    <!-- 侧边栏 -->
    <div class="sidebarBox">
     
      <div class="sidebarItem1 flexC cur"  @click="router.push({path:'/shoppingAdd'})">
           <div class="imgs bgImg"></div>
        </div>
        <div class="sidebarItem flexC cur" @click="toTUIKit" @mouseenter="wxCodeShow=true" @mouseleave="wxCodeShow=false">
           <div class="imgs bgImg"></div>
        </div>
       <div class="sidebarItem2 flexC cur"  @click="getWxContact">
           <div class="imgs bgImg"></div>
         </div>
        <!--<div class="kefuCode" v-if="wxCodeShow">
          <div class="imgCode"><img src="@/assets/img/wx.png" alt="" style="width: 100%;"></div>
          <div class="fontS14 co3 text">请扫码添加平台企业微信联系</div>
        </div>-->
       
    </div>
  </div>
</template>

<script setup>
import { reactive, ref,onMounted,watch,onDeactivated,onBeforeMount,onActivated} from 'vue'
import {getCateTree,goodsList,getUserInfo, getContact} from '@/utils/api'
import AllProduct from './allProduct.vue'
// import WechatService from '@/components/WechatService.vue'
import {useRouter,useRoute} from 'vue-router'
import { ElMessage } from "element-plus";
const router= useRouter()
const route= useRoute()
import { useStore } from "vuex";
const store = useStore()
// ====企业微信=======
const wechatShow=ref(false)
const contactData = ref('')
 const getWxContact=()=>{
  wechatShow.value=true
  getContact({type:1}).then(res=>{
    if(res.code==200){
      contactData.value=res.data
    }
  })
 }
// =========================
// 获取元素滚动条的位置
const isScorllBar=ref(0)
const homeWrap=ref(null)
const bottomHeight=ref(0)//页面高度
// 客服
const metaData=ref('')
const toTUIKit=()=>{
  if(!localStorage.getItem('ntxToken')){
    ElMessage.warning('请先登录')
    return  setTimeout(()=>{
      router.push({path:'/login'})
    },500)
  }
  var chatParamObj = {};
  chatParamObj.echatTag ='Home';
  chatParamObj.metaData=metaData.value
  chatParamObj.lan ='zh';
  // chatParamObj.acdStaffId='19959'//测试的客服
 var chatWindowParam = { name:"Home",width:800,height:600};
 chatParamObj.chatWindowParam = chatWindowParam;
  ECHAT.customMiniChat(chatParamObj)
}
const handleScroll=(event)=>{
  const { scrollTop, clientHeight, scrollHeight } = event.target;
  bottomHeight.value=scrollHeight
  isScorllBar.value=homeWrap.value.scrollTop
}
onActivated(()=>{
 homeWrap.value.scrollTop=isScorllBar.value
})
const num=ref('')
const getList=()=>{
  homeWrap.value.scrollTop=0
  listData.page=1
  page.value=1
  num.value=num.value==''?1:''
  getGoodsList({goods_kind: num.value})
}

const noticeShow=ref(false)
const getDetails=(item)=>{
  router.push({path:'/productDetails',query:{productId:item.id}})
}
// // 分页
const isScorll=ref(0)
const page=ref(1)
const loadlistPage = () => {
  if (page.value < last_page.value) {
      page.value++
      // push
      goodsData.value=goodsData.value.concat(allList.value.slice(goodsData.value.length,Number(`${page.value}0`)))
  }
  
};
const current_page=ref(0)
const handleCurrentChange=(val)=>{
  homeWrap.value.scrollTop=0
  listData.page=val
  getGoodsList({})

}
// 二维码显示
const wxCodeShow=ref(false)
const showKey=ref(true)
const productSearch=ref('')
onBeforeMount(()=>{
  // window.addEventListener('scroll', scrollFn)
  cateTreeList()
  // 其他页面搜索
    // let routerTab=router.currentRoute.value.query.tab;
    // let keyWords=router.currentRoute.value.query.keyWord;
    // if(routerTab==2){tabIndex.value=1}
    // if(keyWords!=''&&keyWords!=undefined){
    //   showKey.value=false;
    // }
    getGoodsList({})
    if(localStorage.getItem('ntxToken')){
      getInfo()
  }
})

// 用户信息
const getInfo=()=>{
    getUserInfo({}).then(res=>{
        if(res.code==200){
          metaData.value=res.data.echat_data
           if(res.data.auth_status==0){
            noticeShow.value=true
           }
        }
    })
}
// 商品列表
// 1页60条，滚动只能滚动6次
const goodsData=ref([])
const last_page=ref(6)
const allpage=ref(0)
const allList=ref([])
const listData=reactive({
  page:1,limit:60,
  keyword:''
})
const total=ref(0)
const getGoodsList=(data)=>{
  listData.keyword=store.state.goodskeyword
  goodsList(Object.assign(listData,data)).then(res=>{
    if(res.code==200){ 
      page.value=1
      allList.value=res.data.data
      goodsData.value=allList.value.slice(0,10)
      allpage.value=Math.ceil(res.data.total/60)
      total.value=res.data.total
      current_page.value=res.data.current_page
     
    }
  })
}

// 分类
const classShow=ref(false)
const activecategry=ref(0)
const allProductCom=ref() 
const categryClick = (index,index1,index2,val)=>{
  tabIndex.value=1; 
  listData.page=1
  page.value=1
  classShow.value=false;
  setTimeout(() => {
    allProductCom.value.changeIndexClass(index,index1,index2,val)
  }, );
 
}


// 商品树
const classList=ref([])
const cateTreeList=()=>{
  getCateTree({}).then(res=>{
    if(res.code==200){
      classList.value=res.data
    }
  })
}
// tab
const nav=ref([{name:'首页'},{name:'全部商品'},{name:'日本直邮'},{name:'保税发货'}])
const tabIndex=ref(0)
const changeTab=(index)=>{
  tabIndex.value=index
  showKey.value=true
  store.commit('updateKeywordr','')
  listData.page=1
  page.value=1
  router.replace({path:'/',query:{}})
  if(tabIndex.value==2){
    getGoodsList({is_direct:1,is_bonded:'',goods_brand_id:'',goods_category_id:'',order:['multiple','desc']})
  }else if(tabIndex.value==3){
    getGoodsList({is_bonded:1,is_direct:'',goods_brand_id:'',goods_category_id:'',order:['multiple','desc']})
  }else if(tabIndex.value==1){
    getGoodsList({is_bonded:'',is_direct:'',goods_brand_id:'',goods_category_id:'',order:['multiple','desc']})
  }else{
    console.log('进入',listData)
    getGoodsList({is_bonded:'',is_direct:'',goods_brand_id:'',goods_category_id:'',order:[]})
  }

}
// // 搜索
onDeactivated(()=>{
  store.commit('updateKeywordr','')
  // window.removeEventListener('scroll', scrollFn)
})
watch(() =>store.state.userConfirm,(newVal, oldVal) =>{
  console.log(newVal,'newVal',oldVal)
  if(newVal>'-1'||oldVal>'-1'){
    tabIndex.value=1
    if(store.state.goodskeyword){
      showKey.value=false
    }else{
      showKey.value=true
    }
    console.log(store.state.goodskeyword,'store.state.goodskeyword')
    listData.page=1
    page.value=1
    getGoodsList({keyWord:store.state.goodskeyword})
    store.commit('updateUserConfirm',-1)
  }
  
},{ immediate:true, deep:true })
</script>
<style lang="scss" scoped>

.pageWrap4{
  height: 66px;
    width: 1392px;
    position: relative;
    padding-right: 24px;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    justify-content: flex-end;
    right: 0;
    box-sizing: border-box;
    z-index: 99;
    left: 50%;
    transform: translateX(-50%);
  }
  
.listItem:nth-child(5n){
    margin-right: 0 !important;
}
.noticeBtn{padding: 6px 15px;border-radius:6px;background: #D22D25;}
.homeWrap::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.homeWrap{position: relative;}
.homeWrap::-webkit-scrollbar-track {
  background-color:transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
.homeWrap::-webkit-scrollbar-thumb {
  background-color: rgb(147,147,153,0.5);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
.imgWeChat{width: 200px;height: 200px;overflow: hidden;margin:30px auto 20px;background: rebeccapurple;}
// .homeWrap{
//   // height: 86vh;
//   scrollbar-width: none; /* firefox */
//         -ms-overflow-style: none; /* IE 10+ */
// }
// .homeWrap::-webkit-scrollbar {
//   display: none; /* Chrome Safari */}
// 侧边栏
.sidebarBox{
    position:fixed;
    width: 72px;
   bottom: 160px;
    right:5%; 
    height:288px;
    background: #fff;
    border: 1px solid #D6D6D6;
    box-shadow: 2px 2px 20px 0px #6666661F;
  border-radius: 4px;
  .imgCode{
    width: 157px ;
    height: 157px;
    background: #D6D6D6;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .text{
    width: 133px;
    margin: auto;
    text-align: center;
  }
  .sidebarItem{
    width: 72px;
    height: 72px;
    border-bottom: 1px solid #D6D6D6;
    .imgs{
      background-image: url('@/assets/img/icon_kefu.png');
      width:28px;height:28px;margin-bottom:3px;
    }
  }
  .sidebarItem:hover{
    background:#FFF5F5;
    .imgs{
    background-image: url('@/assets/img/icon_kefu1.png');
    }

  }
  .sidebarItem1{
    width: 72px;
    height: 72px;
    border-bottom: 1px solid #D6D6D6;
    .imgs{
      background-image: url('@/assets/img/shopactive.png');
      width:28px;height:28px;margin-bottom:3px;
    }
  }
  .sidebarItem1:hover{
    background:#FFF5F5;
    .imgs{
    background-image: url('@/assets/img/shopingis.png');
    }

  }
  .sidebarItem2{
    width: 72px;
    height: 72px;
    border-bottom: 1px solid #D6D6D6;
    .imgs{
      background-image: url('@/assets/img/icon-mxp-default.png');
      width:28px;height:28px;margin-bottom:3px;
    }
  }
  .sidebarItem2:hover{
    background:#FFF5F5;
    .imgs{
    background-image: url('@/assets/img/icon-mxp-select.png');
    }

  }
  .kefuCode{
    position: absolute;
    right: 72px;
    bottom: 72px;
    width: 177px;
    height: 230px;
    border: 1px solid #D6D6D6;
    background:#FFF;
  }
}
// 分类
.classWrapBox{
  position: absolute;
  width: 1168px;
  height: 400px;
  border-radius:0  0 12px  12px;
  margin-top: -5px;
  background: #fff;
  box-shadow: 2px 2px 20px 0px #66666614;
  overflow: auto;
  z-index: 99;
  .classRight{
    .rightItem{
      align-items: flex-start;
      .title:hover{
        color: #D22D25 !important;
        .more{
          background-image: url('@/assets/img/icon_more_hover.png');
        }
      }
      .more{
        width: 14px;height: 14px;
        background-image: url('@/assets/img/icon_more.png');
        margin-top: 3px;
      }
    }
    .productWrap{
      margin-left: 26px;
    }
    .productItem:hover{
      cursor: pointer;
      .productImg{
        border: 1px solid #D22D25;
      }
      .txt{
        color: #D22D25 ;
      }
    }
    .productItem{
      width: 80px;
      height: 102px;
      text-align: center;
     .productImg{
      width: 80px;
      height: 80px;
      border-radius: 6px;
      // background: #ffd1cf;
      margin-bottom:5px;
      overflow: hidden;
     }
    }
  }
  .classLeft{
    width:124px;
    text-align: center;
    overflow-y:scroll;
    overflow-x:hidden ;
   .classItem{
    width:124px;
    height: 50px;
    line-height: 50px;
   } 
   .classItem:hover{
    background: #FFF5F5;
    color: #D22D25;
   }
   .activeItem{
    background: #FFF5F5;
    color: #D22D25;
   }
  }
  .classLeft::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.classLeft::-webkit-scrollbar-track {
  background-color:transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
.classLeft::-webkit-scrollbar-thumb {
  background-color: rgb(147,147,153,0.5);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
}
.classRight {
  width:1018px;
  overflow-y:scroll;
    overflow-x:hidden ;
}
// .classRight::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
// }

// .classRight::-webkit-scrollbar-track {
//   background-color:transparent;
//   -webkit-border-radius: 2em;
//   -moz-border-radius: 2em;
//   border-radius:2em;
// }
// .classRight::-webkit-scrollbar-thumb {
//   background-color: rgb(147,147,153,0.5);
//   -webkit-border-radius: 2em;
//   -moz-border-radius: 2em;
//   border-radius:2em;
// }
.listBox{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
     
  .listItem{
    width:215px;
    height: 368px;
    background: #fff;
    border-radius: 12px;
    margin-right: 23px;
    box-sizing: border-box;
    overflow: hidden;
    .itemTop{
      height: 210px;
      // background: #ffd1cf;
      
    }
    .itemBottom{
      padding:0 10px;
      margin-top: 14px;
    }
  }
}
.banner{
  width: 1168px;
  height: 246px;
  // background:sandybrown;
  border-radius: 12px;
  overflow: hidden;
}
.homeTop{
  position: relative;
  width: 1168px;
height: 68px;
border-radius: 12px;
padding: 0 20px;
box-sizing: border-box;
box-shadow: 2px 2px 20px 0px #66666614;
background: #FFFFFF;
.navItem{
  margin-right: 60px;
  position: relative;
}
.navItem:last-child{
  margin-right:0;
}
.navActiveItem{
  color: #D22D25;
}
.navActiveItem::after{
  content: '';
  display: inline-block;
  width:24px;
  height: 4px;
  border-radius: 4px;
  background: #D22D25;
  position: absolute;
  bottom: -25px;
  left: 50%;
    transform: translateX(-50%);
}
}
</style>
